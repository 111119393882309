import React from "react"
import CrossLogo from "../images/CCLogo-White.png"
import Step1 from "../images/step1.jpg"
import Step2 from "../images/step2.jpg"
import Step3 from "../images/step3.jpg"
import "typeface-montserrat"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import PodcastHead from "../components/PodcastHead"

const Wrap = styled.div`
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
`

const LinksList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const Links = styled.li`
  margin: 14px 0;
`

const Link = styled.a`
  padding: 14px;
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-color: #f07525;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  transition: ease all 0.3s;
  color: #edf2f7;
  :hover {
    opacity: 0.9;
  }
`

const IndexPage = () => (
    <>
        <Global
            styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
        }

        a {
          color: #edf2f7;
          font-weight: 700;
        }

        html,
        body {
          margin: 0;
          color: #edf2f7;
          font-family: -apple-system, BlinkMacSystemFont, "Montserrat",
            "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
          font-size: 14px;
          line-height: 1.4;
          background-color: #121214;
          padding: 15px;

          / *remove margin for the main div that Gatsby mounts into */ > div {
            margin-top: 0;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #312e2e;
              line-height: 1.1;

              + * {
                margin-top: 0.5rem;
              }
            }

            strong {
              color: #312e2e;
            }

            li {
              margin-top: 0.25rem;
            }
          }
        }
      `}
        />
        <SEO title="Cross Church Connect" />
        <Wrap>
            <div
                css={css`
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
            >
                <img
                    src={CrossLogo}
                    css={css`
            width: 30%;
          `}
                />
                <span
                    css={css`
            margin-top: 1rem;
            font-weight: 800;
            font-size: 1.8rem;
          `}
                >
                    Connect @ Cross Church
                </span>
            </div>
            <div
                css={css`
          margin-top: 4rem;
        `}
            >
                <LinksList>
                    <Links>
                        <Link href="sms:94000?&body=VISIT">👋 &nbsp; I'm new here</Link>
                    </Links>
                    <Links>
                        <Link href="sms:94000?&body=REGULAR">
                            ✅ &nbsp; I'm here every week
                        </Link>
                    </Links>
                    <Links>
                        <Link href="http://crosschurchcares.com/give">
                            💲 &nbsp; I want to give
                        </Link>
                    </Links>
                    <Links>
                        <Link href="https://cccares.formstack.com/forms/isaidyes">
                            🤚 &nbsp; I said yes
                        </Link>
                    </Links>
                    <Links>
                        <Link href="https://cccares.formstack.com/forms/baptism">
                            🌊 &nbsp; I want to get baptized
                        </Link>
                    </Links>
                    <Links>
                        <Link href="https://cccares.formstack.com/forms/pizzawiththepastor">
                            🍕 &nbsp; Pizza with the Pastor
                        </Link>
                    </Links>
                    <Links>
                        <Link href="sms:6232444203?&body=ASKPASTOR">
                            🙋 &nbsp; I have a question
                        </Link>
                    </Links>
                </LinksList>
                <div
                    css={css`
            padding: 10px;
            background-color: #fff;
            border-radius: 4px;
          `}
                >
                    <h3
                        css={css`
              margin: 0;
              color: #202024;
            `}
                    >
                        Looking for Sermon Notes?
                    </h3>
                    <p
                        css={css`
              margin: 5px 0;
              font-size: 13px;
              color: #202024;
            `}
                    >
                        Download the latest{" "}
                        <a
                            href="https://www.youversion.com/the-bible-app/"
                            target="_blank"
                            css={css`
                color: #000;
              `}
                        >
                            YouVersion Bible App
                        </a>{" "}
                        available on the Google Play or iPhone App Store.
                        <br />
                        <br />
                        <h4>
                            Select <em>More</em> from the menu bar at the bottom of the Bible
                            app.
                        </h4>
                        <img
                            src={Step1}
                            css={css`
                width: 100%;
                margin-top: 1rem;
              `}
                        />
                        <h4
                            css={css`
                margin-top: 1.5rem;
              `}
                        >
                            Click on <em>Events.</em>
                        </h4>
                        <img
                            src={Step2}
                            css={css`
                width: 100%;
                margin-top: 1rem;
              `}
                        />
                        <h4
                            css={css`
                margin-top: 1.5rem;
              `}
                        >
                            Search for the Cross Church campus that you are attending. You may
                            also see the notes for campuses that are near you if you are
                            attending in-person.
                        </h4>
                        <img
                            src={Step3}
                            css={css`
                width: 100%;
                margin-top: 1rem;
              `}
                        />
                    </p>
                </div>
            </div>
        </Wrap>
    </>
)

export default IndexPage
